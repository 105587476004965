* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-container {
    display: flex;
}

main {
    padding: 10px;
}

.title {
    font-size: 3rem;
    display: grid;
    place-items: center;
}

.sidebar {
    /* background: #F15F4B; */
    background: #014c62;
    color: black;
    height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-bottom: 20px;
}

.sidebar::-webkit-scrollbar {
    width: 0;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.top_section {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 1.55rem 1rem;
    padding: 0.3rem 0.3rem;
    font-family: 'Philosopher';
    font-weight: 800;
    font-size: 1.1rem;
    color: black;
    background-color: #ffffff;
}

.top_section_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
    background-color: #fff;
}

.logo {
    font-size: 18px;
    line-height: 0;
}

.bars {
    width: 30px;
}

.hide {
    display: none;
}

.search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
}

.search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);
    color: white;
}

.routes {
    padding: 15px 0 0 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.link {
    display: flex;
    align-items: center;
    color: #ffffff;
    gap: 10px;
    padding: 5px 10px;
    text-decoration: none;
    font-family: 'Philosopher';
    border-right: 4px solid transparent;
    margin: 5px 10px 5px 0;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
    border-right: 4px solid white;
    background: #ffffff;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: #006d8d;
    border-radius: 8px;
    margin: 5px 10px 5px 0;
}

.link_text {
    white-space: nowrap;
    font-size: 15px;
}

.link_text {
    white-space: nowrap;
    font-size: 15.5px;
    font-weight: 500;
    font-family: 'Philosopher';
    padding: 2px 0;
}

.active {
    margin: 5px 10px 5px 0;
    background: #ffffff;
    color: #006d8d !important;
    border-right: 4px solid white;
    border-radius: 8px;
}

.display-none {
    display: none;
}

.menu {
    display: flex;
    align-items: center;
    /* color: #FFF; */
    color: #ffffff;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
    cursor: pointer;
    margin: 5px 10px 5px 0;
}

.menu:hover {
    border-right: 4px solid white;
    background: #ffffff;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: #006d8d;
    border-radius: 8px;
    margin: 5px 10px 5px 0;
}

.menu_item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.menu_container {
    display: flex;
    flex-direction: column;
}

.menu_container .link {
    padding-left: 20px;
}


.routes{
     background: #006d8d;
}