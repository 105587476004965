@import url('https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  font-family: 'Philosopher';
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes rotation {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}