.label-float {
    position: relative;
    padding-top: 13px;
}

.label-float input {
    border: 1px solid lightgrey;
    border-radius: 5px;
    outline: none;
    min-width: 26rem;
    padding: 1rem;
    font-size: 1.2rem;
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    -webkit-appearance: none;
}

.label-float input:focus {
    border: 2px solid #F48534;
}

.label-float input::placeholder {
    color: transparent;
}

.label-float label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 8px);
    left: 15px;
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    background-color: white;
    padding: 5px;
    box-sizing: border-box;
}

.label-float input:required:invalid+label {
    color: #3951b2;
}

.label-float input:focus:required:invalid {
    border: 2px solid #F48534;
}

.label-float input:required:invalid+label:before {
    content: '';
}

.label-float input:focus+label,
.label-float input:not(:placeholder-shown)+label {
    font-size: 13px;
    top: 0;
    color: #3951b2;
}

.loginButton {
    background: #006d8d;
    width: 100%;
    padding: 0.7rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10;
    color: #fff;
    font-family: 'Philosopher';
    font-size: 1.5rem;
    cursor: pointer;
    border-radius:5px
}